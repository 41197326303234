.admin-logo-container {
    padding-top: 60px;
    padding-left: 110px;
}

.admin-logo {
    width: 200px;
    /*background-color: var(--primary-color);*/
    padding: 20px;
    border-radius: 5px;
}